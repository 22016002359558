import { Direction, FlattenedStopCfg, Route, StopCfg } from "./models"

export const TRANSIT_LOOKAHEAD_MINUTES = 20
export const TRANSIT_REFRESH_INTERVAL = 1000 * 30
export const METEO_REFRESH_INTERVAL = 1000 * 60 * 3

export const DIRECTIONS = ["Downtown", "Waikiki"] as string[]

export const ROUTES: {[name: string]: Route} = {
  "2": { name: "2", color: "brown" },
  "3": { name: "3", color: "brown" },
  "4": { name: "4", color: "brown" },
  "6": { name: "6", color: "green" },
  "7": { name: "7", color: "green" },
  "8": { name: "8", color: "brown" },
  "9": { name: "9", color: "green" },
  "13": { name: "13", color: "brown" },
  "20": { name: "20", color: "green" },
  "23": { name: "23", color: "green" },
  "42": { name: "42", color: "blue" },
  "E": { name: "E", color: "blue" },
  "60": { name: "60", color: "blue" },
  "65": { name: "65", color: "blue" },
  "67": { name: "67", color: "blue" },
}

export const STOPS_CFG: StopCfg[] = [{  // In priority order
  number: 879,
  name: "Ala Moana / Kalia",
  routes: ["20", "23", "42"],
  direction: 0
}, {
  number: 880,
  name: "Ala Moana / Hobron",
  routes: ["E"],
  direction: 0
}, {
  number: 886,
  name: "Kalia / Paoa Pl",
  routes: ["20", "23", "42", "E"],
  direction: 1
}, {
  number: 31,
  name: "Kalakaua / Mccully",
  routes: ["2", "8", "13"],
  direction: 0
}, {
  number: 147,
  name: "Kalakaua / Ena",
  routes  : ["2", "8", "13"],
  direction: 1
}, {
  name: "Ala Moana Center",
  direction: 0,
  stops: [
    { 
      number: 428,
      routes: ["60", "65", "67"]
    }, { 
      number: 761,
      routes: ["6"]
    }
  ]
}]

const flattenStop = (s: StopCfg): FlattenedStopCfg[] => {
  if ('stops' in s) {
    return s.stops.flatMap(st => flattenStop({
      ...st,
      name: s.name,
      direction: s.direction
    }))
  } else {
    return [s as FlattenedStopCfg]
  }
}

const flattenStops = (s: StopCfg[]): FlattenedStopCfg[] => s.flatMap(flattenStop)

const processStops = <S>(stops: StopCfg[], fn: (s: FlattenedStopCfg) => S): S[] => {
  const flattenedStops = flattenStops(stops)
  return flattenedStops.map(fn)
}

export const STOPS = processStops(STOPS_CFG, s => ({
  ...s,
  routes: s.routes.map(r => {
    const route = ROUTES[r]
    if (!route) {
      throw new Error(`Route ${r} not found`)
    }
    return route
  }),
  direction: DIRECTIONS[s.direction] as Direction
}))

export const STOP_NUMBERS = processStops(STOPS_CFG, s => s.number)
